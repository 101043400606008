// LOGIN, LOGOUT
export const AUTH_LOGIN = "AUTH_LOGIN"
export const AUTH_LOGOUT = "AUTH_LOGOUT"
export const AUTH_UPDATE = "AUTH_UPDATE"

// ERROR, SUCCESS, RESET
export const AUTH_ERROR = "AUTH_ERROR"
export const AUTH_SUCCESS = "AUTH_SUCCESS"
export const AUTH_RESET = "AUTH_RESET"

// TOKEN EXPIRED
export const AUTH_UNAUTHORIZED = "AUTH_UNAUTHORIZED"

// GENERATE API TOKEN
export const AUTH_SECRET_KEY = "AUTH_SECRET_KEY"