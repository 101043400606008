import React from 'react'
import PropTypes from 'prop-types'

// import helmet for meta
import { Helmet } from "react-helmet"

// import router
import { Link, withRouter } from "react-router-dom"

// import i18n
import { withTranslation } from "react-i18next"

// import deep-equal
import equal from "deep-equal"

// import redux, actions
import { connect } from 'react-redux'
import { addOrder, evaluateOrder, resetOrders, setOrders } from 'store/actions'

// import bootstrap components
import {
	Container, Row, Col, Card, CardBody, NavItem, NavLink, TabContent, TabPane
} from "reactstrap"

// import custom common components
import Breadcrumbs from 'components/common/Breadcrumbs'
import Locator from 'components/common/Locator'

// import custom components
import ComposeTab from './ComposeTab'
import SummaryTab from './SummaryTab'
import SaveTab from './SaveTab'

// import constants
import {
	APP_NAME, ORDERS_ADD, ORDERS_EVALUATE, CUSTOMER_KEY, DESCRIPTION_AUDIO, DESCRIPTION_TEXT, MIN_DESCRIPTION_INPUT, MAX_DESCRIPTION_INPUT
} from 'helpers/constants'

// import utilities
import { formatPhoneNumberForRequest } from 'helpers/utilities'

// TABS
const FORM_TABS = [
	{ key: "compose", label: "Composition" },
	{ key: "summary", label: "Récapitulatif et Coût" },
	{ key: "save", label: "Enregistrement" }
]


class CreateOrders extends React.Component {

	constructor(props) {
		super(props)

		this.state = {
			activeTab: FORM_TABS[0].key,
			locatorVisible: false,
			selectedPoint: {}
		}
	}

	componentDidUpdate(prevProps) {
		try {//Check state updates
			if (!equal(prevProps, this.props)) {
				let { success, option } = this.props
				if ((!success) && (option === "")) {
					if (prevProps.success && (prevProps.option === ORDERS_ADD)) {
						this.setState({
							activeTab: FORM_TABS[0].key,
							locatorVisible: false,
							selectedPoint: {}
						})
					}
				}
			}
		} catch (error) {
			console.log(error)
		}
	}

	componentWillUnmount() {
		try {// Reset state
			this.props.onResetOrders(true)
		} catch (error) {
			console.log(error)
		}
	}

	/**
	 * Toggle form modal.
	 * 
	 * @param {*} option 
	 */
	onToggled = (option = "locatorVisible", visible = false) => {
		this.setState({ [option]: visible })
	}

	/**
	 * On Tab Toggled.
	 * 
	 * @param {*} tab 
	 */
	onTabToggled = (tab) => {
		if (this.state.activeTab !== tab) this.setState({ activeTab: tab })
	}

	/**
	 * onPrevious.
	 * 
	 * @param {*} currentTab 
	 */
	onPrevious = (currentTab) => {
		try {
			if (currentTab !== FORM_TABS[0].key) {
				let currentIndex = FORM_TABS.findIndex((item) => (currentTab === item.key))
				this.onTabToggled(FORM_TABS[(currentIndex - 1)].key)
			}
		} catch (error) {
			console.error(error)
		}
	}

	/**
	 * onNext.
	 * 
	 * @param {*} currentTab 
	 */
	onNext = (currentTab) => {
		try {
			if (currentTab !== FORM_TABS[(FORM_TABS.length - 1)].key) {
				let currentIndex = FORM_TABS.findIndex((item) => (currentTab === item.key))
				let nextTab = FORM_TABS[(currentIndex + 1)].key
				if (this.onValidated(nextTab)) {
					this.onTabToggled(nextTab)
					if (nextTab === "summary") this.onEvaluated()
					else if (nextTab === "save") this.onSaved()
				}
			}
		} catch (error) {
			console.error(error)
		}
	}

	/**
	 * on validated.
	 * Check if user inputs.
	 * 
	 * @param {*} nextTab 
	 * @returns 
	 */
	onValidated = (option = "save") => {
		try {
			let {
				cost, pickup_point, pickup_contact, delivery_point, delivery_contact,
				nature, description_file, description_textual, description_type
			} = this.props.order

			let validate_inputs = (
				(nature && nature !== "") &&
				(pickup_contact && pickup_contact !== "") &&
				(delivery_contact && delivery_contact !== "") &&
				(pickup_point && pickup_point.address && pickup_point.address !== "") &&
				(delivery_point && delivery_point.address && delivery_point.address !== "")
			)

			let validate_description = (
				((description_type === DESCRIPTION_AUDIO) && (description_file && description_file !== "")) ||
				(
					(description_type === DESCRIPTION_TEXT) && description_textual &&
					(
						(description_textual.length >= MIN_DESCRIPTION_INPUT) &&
						(description_textual.length <= MAX_DESCRIPTION_INPUT)
					)
				)
			)

			let validate_all = (validate_inputs && validate_description)

			return ((option === "save") ? (validate_all && (cost && cost.total)) : validate_all)
		} catch (error) {
			console.log(error)
			return false
		}
	}

	/**
	 * on saved.
	 * Submit form, Launch add or payment process.
	 * 
	 */
	onSaved = () => {
		try {
			if (this.onValidated()) {
				let { order, onAddOrder } = this.props
				let { name, phone, _id } = JSON.parse(localStorage.getItem(CUSTOMER_KEY))
				let is_audio = (order.description_type === DESCRIPTION_AUDIO)
				onAddOrder({
					start_lat: order.pickup_point.lat,
					start_lng: order.pickup_point.lng,
					start_address: order.pickup_point.address,
					start_contact: formatPhoneNumberForRequest(order.pickup_contact),
					delivery_lat: order.delivery_point.lat,
					delivery_lng: order.delivery_point.lng,
					delivery_address: order.delivery_point.address,
					delivery_contact: formatPhoneNumberForRequest(order.delivery_contact),
					nature: order.nature,
					is_audio: is_audio,
					description_file: (is_audio && order.description_data) ? order.description_data : "",
					description_filename: (is_audio && order.description_data) ? "description.mp3" : "",
					description_textual: ((!is_audio) && order.description_textual) ? order.description_textual : "",
					customer_contact: (phone) ? formatPhoneNumberForRequest(phone) : "",
					customer_name: (name) ? name : "",
					customer_id: (_id) ? _id : "",
					delivery_auth: order.delivery_auth ? order.delivery_auth : false,
					round_trip: order.round_trip ? order.round_trip : false,
					price: order.cost.total
				}, ORDERS_ADD)
			}
		} catch (error) {
			console.error(error)
		}
	}

	/**
	* On changed.
	* 
	* @param {*} event 
	*/
	onChanged = (event) => {
		try {
			//Get target
			let { checked, value, name, type } = event.target

			//Get props
			let { order, onSetOrders } = this.props

			//Store values
			onSetOrders({
				order: { ...order, [name]: ((type === "checkbox") ? checked : value) }
			})
		} catch (error) {
			console.error(error)
		}
	}

	/**
	* On Located.
	* 
	* @param {*} point 
	*/
	onLocated = (point) => {
		try {
			this.setState({
				selectedPoint: point
			}, () => {
				this.onToggled("locatorVisible", true)
			})
		} catch (error) {
			console.error(error)
		}
	}

	/**
	 * On Picked.
	 * 
	 * @param {*} picked 
	 */
	onPicked = (picked) => {
		try {
			let { order, onSetOrders } = this.props
			let { selectedPoint } = this.state
			this.setState({
				selectedPoint: { ...selectedPoint, ...picked }
			}, () => {
				onSetOrders({ order: { ...order, [selectedPoint.key]: { ...picked } } })
			})
		} catch (error) {
			console.error(error)
		}
	}

	/**
	 * On switched.
	 * 
	 * @param {*} option 
	 */
	onSwitched = (option, name = "description_type") => {
		try {
			// Get props
			let { order, onSetOrders } = this.props

			// Store values
			onSetOrders({
				order: {
					...order, [name]: option
				}
			})
		} catch (error) {
			console.error(error)
		}
	}

	/**
	 * On Recorded.
	 * 
	 * @param {*} audio 
	 */
	onRecorded = (audio) => {
		try {
			//Get props, audio values
			let { order, onSetOrders } = this.props
			let { link, data, initRecord } = audio
			let { description_file, description_data, description_type } = order

			//Set audio value
			onSetOrders({
				order: {
					...order,
					description_file: (
						initRecord ? null : (link !== null) ? link : description_file
					),
					description_data: (
						initRecord ? null : (data !== null) ? data : description_data
					),
					description_type: ((description_type === undefined) ? DESCRIPTION_AUDIO : description_type)
				}
			})
		} catch (error) {
			console.error(error)
		}
	}

	/**
	 * On order cost calculated.
	 * 
	 */
	onEvaluated = () => {
		try {
			const { onEvaluateOrder, order } = this.props
			if (order && order.pickup_point && order.delivery_point) {
				let { pickup_point, delivery_point, round_trip } = order
				onEvaluateOrder({
					s_lat: pickup_point.lat, s_lng: pickup_point.lng,
					d_lat: delivery_point.lat, d_lng: delivery_point.lng,
					rt: (round_trip ? round_trip : false)
				}, ORDERS_EVALUATE)
			}
		} catch (error) {
			console.error(error)
		}
	}

	/**
	 * on reseted.
	 * Reset form after add process.
	 * 
	 */
	onRestarted = () => {
		try {//Reset state values
			this.props.onResetOrders(true)
		} catch (error) {
			console.error(error)
		}
	}

	render() {
		const { t, order, loading, success, error, option } = this.props
		const { activeTab, locatorVisible, selectedPoint } = this.state

		const TABS_SIZE = FORM_TABS.length

		return (
			<React.Fragment>
				<Helmet>
					<title>{APP_NAME} | {t("Nouvelle course")}</title>
				</Helmet>

				<div className="page-content">
					<Container fluid>
						<Breadcrumbs title={t("Nouvelle course")} />

						<Row className="mb-2">
							<Col xl="12">
								<Card>
									<CardBody>
										<div className="wizard clearfix">
											<div className="steps clearfix">
												<ul>
													{FORM_TABS.map((item, index) => (
														<NavItem
															key={`form_nav_item${index}`}
															className={(activeTab === item.key) ? "current" : ""}
														>
															<NavLink
																className={`d-cursor${(activeTab === item.key) ? " active" : ""}`}
															>
																<span className="number">
																	{`${index + 1}`}.
																</span>{" "}
																{item.label}
															</NavLink>
														</NavItem>
													))}
												</ul>
											</div>

											<div className="content clearfix">
												<TabContent activeTab={activeTab} className="body">
													{FORM_TABS.map((item, index) => (activeTab == item.key) && (
														<TabPane key={`form_tab_item_${index}`} tabId={item.key}>
															{(item.key === "compose") ? (
																<ComposeTab
																	order={order}
																	onChanged={this.onChanged}
																	onLocated={this.onLocated}
																	onRecorded={this.onRecorded}
																	onSwitched={this.onSwitched}
																/>
															) : (item.key === "summary") ? (
																<SummaryTab
																	option={option}
																	loading={loading}
																	error={error}
																	order={order}
																	onEvaluated={this.onEvaluated}
																/>
															) : (item.key === "save") ? (
																<SaveTab
																	order={order}
																	loading={loading}
																	option={option}
																	error={error}
																	success={success}
																	onRestarted={this.onRestarted}
																/>
															) : (
																<p className="m-0 p-2">
																	Section inconnue
																</p>
															)}
														</TabPane>
													))}
												</TabContent>
											</div>

											<div className="actions clearfix">
												{(success && (option === ORDERS_ADD)) ? null : (
													<ul>
														<li className={(activeTab === FORM_TABS[0].key) ? "previous disabled" : "previous"}>
															<Link to="#" onClick={() => this.onPrevious(activeTab)}>
																{t("Précédent")}
															</Link>
														</li>
														<li className={(activeTab === FORM_TABS[(TABS_SIZE - 1)].key) ? "next disabled" : "next"}>
															<Link to="#" onClick={() => this.onNext(activeTab)}>
																{t("Suivant")}
															</Link>
														</li>
													</ul>
												)}
											</div>
										</div>
									</CardBody>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
				{(locatorVisible) ? (
					<Locator
						opened={locatorVisible}
						onClosed={this.onToggled}
						onPicked={this.onPicked}
						point={selectedPoint}
					/>
				) : null}
			</React.Fragment>
		)
	}

}
CreateOrders.propTypes = {
	t: PropTypes.any,
	history: PropTypes.any,
	error: PropTypes.string,
	option: PropTypes.string,
	loading: PropTypes.bool,
	success: PropTypes.bool,
	order: PropTypes.object,
	onAddOrder: PropTypes.func,
	onEvaluateOrder: PropTypes.func,
	onSetOrders: PropTypes.func,
	onResetOrders: PropTypes.func,
}
const mapStateToProps = state => ({
	order: state.commandes.order,
	error: state.commandes.error,
	option: state.commandes.option,
	success: state.commandes.success,
	loading: state.commandes.loading
})
const mapDispatchToProps = dispatch => ({
	onSetOrders: (data) => dispatch(setOrders(data)),
	onResetOrders: (all) => dispatch(resetOrders(all)),
	onAddOrder: (data, option) => dispatch(addOrder(data, option)),
	onEvaluateOrder: (data, option) => dispatch(evaluateOrder(data, option))
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(CreateOrders)))